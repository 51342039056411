/* JS */

/* react */
import React from 'react';
/* third part components */

/* custom components */
import Panel from '../components/Panel'
import Hamburger from '../components/Hamburger/Hamburger'
import GeneralInfoForm from '../components/Form/GeneralInfoForm'
import FullInfoForm from '../components/Form/FullInfoForm'
import ATLayout from '../components/ATLayout'
import SEO from '../components/seo'

import './contatti.css'
import 'animate.css'

const check = require('../images/check.svg');
const forward = require('../images/forward-gray.svg');

class ContactPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
			animation: 'fadeIn',
			isMenuOpen: false,
            selectedForm: '',
            completed: false,
		}
		
		this.handleOnHamburgerClick = this.handleOnHamburgerClick.bind(this);

        this.handleChooseGeneralForm = this.handleChooseGeneralForm.bind(this);
        this.handleChooseFullForm = this.handleChooseFullForm.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleBackwardMouseOver = this.handleBackwardMouseOver.bind(this);
        this.handleBackwardMouseOut = this.handleBackwardMouseOut.bind(this);
        this.handleForwardMouseOver = this.handleForwardMouseOver.bind(this);
        this.handleForwardMouseOut = this.handleForwardMouseOut.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.handleOnOutsideMenuClick = this.handleOnOutsideMenuClick.bind(this);
	}
	
	handleOnHamburgerClick(event) {
		let imo = this.state.isMenuOpen;
		this.setState({
			isMenuOpen: !imo
		});
	}

    handleChooseGeneralForm(event) {
        event.preventDefault();
        let self = this;
        self.setState({ animation: 'fadeOut' });
        setTimeout(function () {
            self.setState({ animation: 'fadeIn', selectedForm: 'general', completed: false });
        }, 500);
    }

    handleChooseFullForm(event) {
        event.preventDefault();
        let self = this;
        self.setState({ animation: 'fadeOut' });
        setTimeout(function () {
            self.setState({ animation: 'fadeIn', selectedForm: 'full', completed: false });
        }, 500);
    }

    handleBackClick(event) {
        event.preventDefault();
        let self = this;
        self.setState({ animation: 'fadeOut' });
        setTimeout(function () {
            self.setState({ animation: 'fadeIn', selectedForm: '', completed: false });
        }, 500);
    }

    handleBackwardMouseOver(event) {
        event.currentTarget.querySelector('img').src = require('../images/backward-orange.png');
    }

    handleBackwardMouseOut(event) {
        event.currentTarget.querySelector('img').src = require('../images/backward-gray.png');
    }

    handleForwardMouseOver(event) {
        event.currentTarget.querySelector('img').src = require('../images/forward-orange.png');
    }

    handleForwardMouseOut(event) {
        event.currentTarget.querySelector('img').src = require('../images/forward-gray.png');
    }

    handleSubmit(event) {
        event.preventDefault();
        let self = this;
        self.setState({ animation: 'fadeOut' });
        setTimeout(function () {
            self.setState({ animation: 'fadeIn', selectedForm: '', completed: true });
        }, 500);
    }
	
	handleOnOutsideMenuClick(event) {
		this.setState({
			isMenuOpen: false
		});
	}

    render() {

        return (
			<ATLayout title="Contatti" isMenuOpen={this.state.isMenuOpen} onOutsideMenuClick={this.handleOnOutsideMenuClick}>
				<SEO title="Contatti • Alfatauri Studio" relPath="contatti"
				description="Hai un'idea? Mettiamoci in contatto. Facci sapere ciò di cui hai bisogno."/>
            <div className="at-page">
                <div className="container">
                    <Panel className="py-4">
                        <div className={'animated ' + this.state.animation}>
                            {!this.state.completed && (
                                (this.state.selectedForm === '') ? (
                                    <React.Fragment>
                                        <h3 className="text-center pt-3 pb-5">Mettiamoci in contatto!</h3>

                                        <div className="row justify-content-center">
                                            <div className="col-12 my-3">
                                                <div className="form-chooser-container">
                                                    <button className="btn mb-5 w-100 form-chooser" onClick={this.handleChooseGeneralForm}
                                                        onMouseOver={this.handleForwardMouseOver} onMouseOut={this.handleForwardMouseOut}>
                                                        Informazioni generali
                                                        <br />
                                                        <img className="forward-arrow" alt="General info form arrow" src={forward} />
                                                    </button>
                                                    <button className="btn mb-5 w-100 form-chooser" onClick={this.handleChooseFullForm}
                                                        onMouseOver={this.handleForwardMouseOver} onMouseOut={this.handleForwardMouseOut}>
                                                        Sai già quello che vuoi?
                                                        <br />
                                                        <img className="forward-arrow" src={forward} alt="Full info form" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div key="contactLinksRow" className="row">
                                            <div className="col-12">
                                                <div className="w-100 d-block text-center mb-3">
                                                    <a className="contact-link" href="mailto:info@alfatauristudio.com">info@alfatauristudio.com</a>
                                                </div>
                                                <div className="w-100 d-block text-center">
                                                    <a className=" contact-link" href="tel:00393889844609">+39 388.98.44.609</a>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : (this.state.selectedForm === 'general') ? (
                                    <GeneralInfoForm key="generalInfoForm"
                                        onBackClick={this.handleBackClick} onSubmit={this.handleSubmit}
                                        onBackMouseOver={this.handleBackwardMouseOver} onBackMouseOut={this.handleBackwardMouseOut} />
                                ) : (this.state.selectedForm === 'full') && (
                                    <FullInfoForm key="fullInfoForm"
                                        onBackClick={this.handleBackClick} onSubmit={this.handleSubmit}
                                        onBackMouseOver={this.handleBackwardMouseOver} onBackMouseOut={this.handleBackwardMouseOut} />
                                )
                            )}

                            {this.state.completed && (
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col-12 ml-4 mb-4">
                                            <button className="btn backward-arrow-container p-0" onClick={this.handleBackClick}
                                                onMouseOver={this.handleBackwardMouseOver} onMouseOut={this.handleBackwardMouseOut}>
                                                <img className="backward-arrow" alt="Backward form arrow" src={require('../images/backward-gray.png')} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-8 my-5 text-center">
                                            <img src={check} style={{ width: "50px", height: "50px" }} alt="Form completed" />
                                            <p className="completed-text my-4">
                                                <strong>Ben fatto!</strong>
                                                <br />Ti risponderemo ASAP
                                            </p>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </Panel>
                </div>

				<Hamburger open={this.state.isMenuOpen} //className={this.props.isMenuOpen ? 'open' : ''}
					onClick={this.handleOnHamburgerClick} />
            </div>
			</ATLayout>
        );
    };
}

export default ContactPage;