import React from 'react'

import ButtonNext from '../Button/ButtonNext'
import Slider from 'rc-slider/lib/Slider'

import 'rc-slider/assets/index.css'
import 'animate.css'

import './InfoForm.css';

const backward = require('../../images/backward-gray.svg');
const attach = require('../../images/attach.svg');
const shuttle = require('../../images/shuttle.png');

class FullInfoForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            animation: 'fadeIn',
            error: ' ',
            page: 1,
            selectedBelieve: 0,
            services: [
                'Branding',
                'Software',
                'Website',
                'Marketing'
            ],
            selectedServices: [],
            name: '',
            company: '',
            email: '',
            phone: '',
            time: '4 sett.',
            website: '',
            message: '',
            attachedFile: null,
            width: window.innerWidth
        };

        this.fileInput = null;

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleWebsiteChange = this.handleWebsiteChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleGoToPage2 = this.handleGoToPage2.bind(this);
        this.handleGoToPage3 = this.handleGoToPage3.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleBelieveClick = this.handleBelieveClick.bind(this);
        this.handleServiceClick = this.handleServiceClick.bind(this);
        this.handleSliderAfterChange = this.handleSliderAfterChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);

        this.handleOnAttachButtonClick = this.handleOnAttachButtonClick.bind(this);
        this.handleOnAttachFileChange = this.handleOnAttachFileChange.bind(this);

        this.handleOnLaunch = this.handleOnLaunch.bind(this);

        this.handleOnWindowSizeChange = this.handleOnWindowSizeChange.bind(this);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleOnWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleOnWindowSizeChange);
    }

    handleOnWindowSizeChange() {
        this.setState({ width: window.innerWidth });
    };

    handleNameChange(event) {
        event.preventDefault();
        this.setState({ name: event.target.value });
    }

    handleCompanyChange(event) {
        event.preventDefault();
        this.setState({ company: event.target.value });
    }

    handleEmailChange(event) {
        event.preventDefault();
        this.setState({ email: event.target.value });
    }

    handlePhoneChange(event) {
        event.preventDefault();
        this.setState({ phone: event.target.value });
    }

    handleWebsiteChange(event) {
        event.preventDefault();
        this.setState({ website: event.target.value });
    }

    handleMessageChange(event) {
        event.preventDefault();
        this.setState({ message: event.target.value });
    }

    nextPage() {
        let self = this;
        self.setState({ animation: 'fadeOut' });
        setTimeout(function () {
            self.setState((prevState) => { return { animation: 'fadeIn', error: '', page: prevState.page + 1 } });
        }, 500);
    }

    handleGoToPage2(event) {
        event.preventDefault();
        if (this.state.name === '') this.setState({ error: 'Inserisci il tuo nome per continuare.' });
        else if (this.state.email === '') this.setState({ error: 'Inserisci la tua e-mail per continuare.' });
        else if (!this.validateEmail(this.state.email)) this.setState({ error: 'Inserisci una e-mail valida per continuare.' });
        else this.nextPage();
    }

    handleGoToPage3(event) {
        event.preventDefault();
        this.nextPage();
    }

    handleBackClick(event) {
        event.preventDefault();
        if (this.state.page === 1) {
            this.props.onBackClick(event);
        } else {
            this.setState({ page: this.state.page - 1 });
        }
    }

    handleBelieveClick(event) {
        this.setState({ selectedBelieve: parseInt(event.currentTarget.getAttribute('value'), 10) });
    }

    handleServiceClick(event) {
        const serviceName = event.target.value;
        let ss = [];
        if (!this.state.selectedServices.includes(serviceName)) {
            ss = this.state.selectedServices;
            ss.push(serviceName);
        } else ss = this.state.selectedServices.filter(sn => sn !== serviceName);
        this.setState({
            selectedServices: ss
        });
    }

    handleSliderAfterChange(value) {
        switch (value) {
            case 0:
                this.setState({ time: '4 sett.' });
                break;

            case 1:
                this.setState({ time: '2 mesi' });
                break;

            case 2:
                this.setState({ time: '3 mesi' });
                break;

            case 3:
                this.setState({ time: '4 mesi' });
                break;

            case 4:
                this.setState({ time: '1 anno' });
                break;

            case 5:
                this.setState({ time: 'più di 1 anno' });
                break;

            default:
                this.setState({ time: 'più di un anno' });
                break;
        }
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleOnAttachButtonClick(event) {
        this.fileInput.click()
    }

    handleOnAttachFileChange(event) {
        let file = event.target.files[0];

        this.setState({
            attachedFile: file
        })
    }

    handleOnLaunch(event) {
        event.preventDefault();
        let self = this;

        let payload = new FormData();
        payload.append('name', this.state.name);
        payload.append('company', this.state.company);
        payload.append('believe', this.state.selectedBelieve === 0 ? 'Poco...' : this.state.selectedBelieve === 1 ? 'OK' : 'Molto!');
        payload.append('services', this.state.selectedServices.join(', '));
        payload.append('email', this.state.email);
        payload.append('phone', this.state.phone);
        payload.append('time', this.state.time);
        payload.append('website', this.state.website);
        payload.append('message', this.state.message);
        payload.append('file', this.state.attachedFile);

        //console.log(payload);

        fetch('https://alfatauristudio.it/contact-full.php', {
            method: 'POST',
            body: payload
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.success) {
                    console.log('success');
                    self.props.onSubmit(event);
                }
            })
    }

    render() {
        const isMobile = this.state.width <= 812;

        const servicesCheckboxes = this.state.services.map((serviceName, index) =>
            <div key={serviceName} className="form-check col-4 mb-4 pl-4 text-left">
                <input className="form-check-input" type="checkbox" id={"serviceCheckbox" + index} value={serviceName}
                    checked={this.state.selectedServices.includes(serviceName)} onChange={this.handleServiceClick} />
                <label className="form-check-label ml-1" htmlFor={"serviceCheckbox" + index}>{serviceName}</label>
            </div>
        );

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 ml-4 mb-4">
                        <button className="btn backward-arrow-container p-0" onClick={this.handleBackClick}
                            onMouseOver={this.props.onBackMouseOver} onMouseOut={this.props.onBackMouseOut}>
                            <img className="backward-arrow" alt="Backward form arrow" src={backward} />
                        </button>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-10 col-lg-8 form-container">
                        <div className={'animated ' + this.state.animation}>
                            {this.state.page === 1 ? (
                                <form key='form1' className='at-info-form'>
                                    <div className="form-row">
                                        <div className="form-group col-12 col-lg-6 mb-4">
                                            <input name="name" type="text" className="form-control" id="inputName" placeholder="Nome*" value={this.state.name}
                                                onChange={this.handleNameChange} required />
                                        </div>
                                        <div className="form-group col-12 col-lg-6 mb-4">
                                            <input name="company" type="text" className="form-control" id="inputCompany" placeholder="Azienda" value={this.state.company}
                                                onChange={this.handleCompanyChange} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12 col-lg-6 mb-4">
                                            <input name="email" type="email" className="form-control" id="inputEmail" placeholder="E-mail*" value={this.state.email}
                                                onChange={this.handleEmailChange} required />
                                        </div>
                                        <div className="form-group col-12 col-lg-6 mb-4">
                                            <input name="phone" type="number" className="form-control" id="inputPhone" placeholder="Telefono" value={this.state.phone}
                                                onChange={this.handlePhoneChange} />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className={"col-12 " + (isMobile ? "mt-4 mb-2" : "my-4")}>
                                            <label>Quanto credi nella tua idea?</label>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className={"form-check col-12 col-lg-4 text-left " + (isMobile ? "mb-3" : "mb-4 mt-2")}>
                                            <svg className={"radio " + ((this.state.selectedBelieve === 0) ? "checked" : "unchecked") + " pr-2"} value="0" onClick={this.handleBelieveClick}
                                                shapeRendering="optimizeQuality" preserveAspectRatio="xMidYMid meet" viewBox="0 0 64 64">
                                                <circle className="outer" fill="#BDBDBD" cx="32" cy="32" r="32"></circle> <circle className="inner" fill="#FFFFFF" cx="32" cy="32" r="28"></circle>
                                            </svg>
                                            <label className="form-check-label">Più o meno...</label>
                                        </div>
                                        <div className={"form-check col-12 col-lg-4 " + (isMobile ? "text-left mb-3" : "text-center mb-4 mt-2")}>
                                            <svg className={"radio " + ((this.state.selectedBelieve === 1) ? "checked" : "unchecked") + " pr-2"} value="1" onClick={this.handleBelieveClick}
                                                shapeRendering="optimizeQuality" preserveAspectRatio="xMidYMid meet" viewBox="0 0 64 64">
                                                <circle className="outer" fill="#BDBDBD" cx="32" cy="32" r="32"></circle> <circle className="inner" fill="#FFFFFF" cx="32" cy="32" r="28"></circle>
                                            </svg>
                                            <label className="form-check-label">Penso che sia ok</label>
                                        </div>
                                        <div className={"form-check col-12 col-lg-4 " + (isMobile ? "text-left mb-4" : "text-right mb-4 mt-2")}>
                                            <svg className={"radio " + ((this.state.selectedBelieve === 2) ? "checked" : "unchecked") + " pr-2"} value="2" onClick={this.handleBelieveClick}
                                                shapeRendering="optimizeQuality" preserveAspectRatio="xMidYMid meet" viewBox="0 0 64 64">
                                                <circle className="outer" fill="#BDBDBD" cx="32" cy="32" r="32"></circle> <circle className="inner" fill="#FFFFFF" cx="32" cy="32" r="28"></circle>
                                            </svg>
                                            <label className="form-check-label">E' un'idea grandiosa!</label>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-12 col-lg-10 d-flex justify-content-start">
                                            <p className="error">{this.state.error}</p>
                                        </div>
                                        <div className="col-2 offset-5 offset-lg-0 d-flex justify-content-end">
                                            <ButtonNext className="next-page" onClick={this.handleGoToPage2} type="submit" />
                                        </div>
                                    </div>
                                </form>
                            ) : this.state.page === 2 ? (
                                <form key='form2' className='at-info-form'>
                                    <div className="form-row">
                                        <div className="col-12 mb-4">
                                            <label>Servizi</label>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        {servicesCheckboxes}
                                    </div>

                                    <div className="form-row">
                                        <div className="col-12 my-3">
                                            <label>Tempistiche</label>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-12 mb-4 px-2 timeline-container">
                                            <Slider dots min={0} max={5} defaultValue={2} step={1}
                                                trackStyle={{
                                                    backgroundColor: '#F49131',
                                                    height: 4,
                                                    marginTop: -2
                                                }}
                                                handleStyle={{
                                                    borderColor: '#F49131',
                                                    height: 12,
                                                    width: 12,
                                                    marginTop: -6,
                                                    marginLeft: -6,
                                                    backgroundColor: '#F49131'
                                                }}
                                                dotStyle={{
                                                    borderColor: 'transparent',
                                                    height: 12,
                                                    width: 12,
                                                    marginTop: -6,
                                                    marginLeft: -6,
                                                    backgroundColor: 'transparent'
                                                }}
                                                activeDotStyle={{ backgroundColor: 'transparent' }}
                                                railStyle={{
                                                    backgroundColor: '#ebedef',
                                                    height: 4,
                                                    marginTop: -2
                                                }}
                                                onChange={this.handleSliderAfterChange} />
                                            <label>{this.state.time}</label>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-12 col-lg-10 d-flex justify-content-start">
                                            <p className="error">{this.state.error}</p>
                                        </div>
                                        <div className="col-2 offset-5 offset-lg-0 d-flex justify-content-end">
                                            <ButtonNext className="next-page" onClick={this.handleGoToPage3} type="submit" />
                                        </div>
                                    </div>
                                </form>
                            ) : (
                                <form key='form3' className="at-info-form" encType="multipart/form-data" onSubmit={this.handleOnLaunch} >
                                    <div className="form-row">
                                        <div className="form-group col-12 col-lg-6 mb-4">
                                            <input type="text" className="form-control" id="inputWebsite"
                                                placeholder="Sito web (se ne hai uno)" onChange={this.handleWebsiteChange} />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <label htmlFor="textarea">Messaggio</label>
                                            <textarea className="form-control" id="textarea" rows="5"
                                                placeholder="Spazio libero per le tue idee..." onChange={this.handleMessageChange}></textarea>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <input ref={fileInput => this.fileInput = fileInput} accept="application/pdf,image/*,application/vnd.ms-excel"
                                                className="invisible" type="file" name="file" onChange={this.handleOnAttachFileChange} />
                                            <button className="btn p-0 mt-3 d-flex align-items-center" type="button" onClick={this.handleOnAttachButtonClick}>
                                                <img className="mr-3" src={attach} alt="Allega un file" />
                                                <label className={"m-0 " + (!isMobile ? 'text-right' : 'text-left')}>Allega un file<br /><span>max 10 MB</span></label>
                                            </button>
                                        </div>
                                    </div>

                                    <button type="submit" className="btn my-3 launch-button">
                                        <span style={{ left: '0px', display: 'inline-block' }}>LANCIA</span>
                                        <img src={shuttle} alt="Shuttle" style={{ height: '20px' }} />
                                    </button>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FullInfoForm;