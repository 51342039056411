import React from 'react';

import 'animate.css';

import './InfoForm.css';

const backward = require('../../images/backward-gray.svg');
const shuttle = require('../../images/shuttle.png');

class GeneralInfoForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            company: '',
            email: '',
            phone: '',
            message: ''
        };

        this.handleOnNameChange = this.handleOnNameChange.bind(this);
        this.handleOnCompanyChange = this.handleOnCompanyChange.bind(this);
        this.handleOnEmailChange = this.handleOnEmailChange.bind(this);
        this.handleOnPhoneChange = this.handleOnPhoneChange.bind(this);
        this.handleOnMessageChange = this.handleOnMessageChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.handleOnLaunch = this.handleOnLaunch.bind(this);
    }

    handleOnNameChange(event) {
        event.preventDefault();
        this.setState({ name: event.target.value });
    }

    handleOnCompanyChange(event) {
        event.preventDefault();
        this.setState({ company: event.target.value });
    }

    handleOnEmailChange(event) {
        event.preventDefault();
        this.setState({ email: event.target.value });
    }

    handleOnPhoneChange(event) {
        event.preventDefault();
        this.setState({ phone: event.target.value });
    }

    handleOnMessageChange(event) {
        event.preventDefault();
        this.setState({ message: event.target.value });
    }

    handleOnLaunch(event) {
        event.preventDefault();
        if (this.state.name === '') this.setState({ error: 'Inserisci il tuo nome per continuare.' });
        else if (this.state.email === '') this.setState({ error: 'Inserisci la tua e-mail per continuare.' });
        else if (!this.validateEmail(this.state.email)) this.setState({ error: 'Inserisci una e-mail valida per continuare.' });
        else {
            let self = this;
            let payload = {
                name: self.state.name,
                company: self.state.company,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message
            };
            console.log(payload);

            fetch('https://alfatauristudio.it/contact-general.php', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(payload)
            })
            .then((data) => data.json())
            .then((data) => {
                if (data.success) {
                    console.log('success');
                    self.props.onSubmit(event);
                }
            });
        }
    };

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 mb-4">
                        <button className="btn backward-arrow-container p-0 ml-4"
                            onClick={this.props.onBackClick} onMouseOver={this.props.onBackMouseOver} onMouseOut={this.props.onBackMouseOut}>
                            <img className="backward-arrow" alt="Backward form arrow" src={backward} />
                        </button>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-10 col-lg-8 form-container">
                        <form className={'at-info-form ' + this.props.className} onSubmit={this.handleOnLaunch}>
                            <div className="form-row">
                                <div className="form-group col-12 col-lg-6 mb-4">
                                    {/* <label htmlFor="inputName">Name*</label> */}
                                    <input type="text" className="form-control" id="inputName" placeholder="Nome*" onChange={this.handleOnNameChange} required />
                                </div>
                                <div className="form-group col-12 col-lg-6 mb-4">
                                    {/* <label htmlFor="inputPassword">Company</label> */}
                                    <input type="text" className="form-control" id="inputCompany" placeholder="Azienda" onChange={this.handleOnCompanyChange} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12 col-lg-6 mb-4">
                                    {/* <label htmlFor="inputEmail">E-mail*</label> */}
                                    <input type="email" className="form-control" id="inputEmail" placeholder="E-mail*" onChange={this.handleOnEmailChange} required />
                                </div>
                                <div className="form-group col-12 col-lg-6 mb-4">
                                    {/* <label htmlFor="inputPhone">Phone</label> */}
                                    <input type="number" className="form-control" id="inputPhone" placeholder="Telefono" onChange={this.handleOnPhoneChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="textarea">Messaggio</label>
                                <textarea className="form-control" id="textarea" rows="5" placeholder="Spazio libero per le tue idee..." onChange={this.handleOnMessageChange}></textarea>
                            </div>

                            <div className="form-row">
                                <div className="col-12 col-lg-10 d-flex justify-content-start">
                                    <p className="error">{this.state.error}</p>
                                </div>
                            </div>
                            
                            <button type="submit" className="btn launch-button">
                                <span style={{ left: '0px', display: 'inline-block'}}>LANCIA</span>
                                <img src={shuttle} alt="Shuttle" style={{height: '20px'}} />
                            </button>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default GeneralInfoForm;